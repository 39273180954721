import React from 'react';
import { Link } from "react-router-dom";
import HorizontalLine from "../HorizontalLine"
import ReactMarkdown from 'react-markdown'

const BlogPostPreview = ( { blogPost } ) => {
    console.log("Getting blog post preview")
    return (
          <div>
              <Link to={"/posts/" + blogPost.blogPostId} style={{ color: '#000000', textDecoration: 'none'}}>
              <h2>{blogPost.title}</h2><HorizontalLine></HorizontalLine>
              </Link>
              <ReactMarkdown>{blogPost.preview}</ReactMarkdown>
              <br/><br/>
          </div>
        )
    }

export default BlogPostPreview