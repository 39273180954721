import React from 'react';
import {Navbar, Container, Nav} from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';

const Navigation = () => {
    
    return (
        <div>
            <Navbar className="fixed-top" bg="light" expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to="/">Paul Minogue</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} to="/about">About</Nav.Link>
                        </Nav>
                        <Navbar.Brand 
                            as={SocialIcon} 
                            className="border-left pl-2 ml-auto"
                            url="https://www.linkedin.com/in/paul-minogue-a6027bb2/" 
                            style={{ height: 30, width: 30 }}
                        />
                        <Navbar.Brand 
                            as={SocialIcon} 
                            url="https://github.com/minoguep" 
                            style={{ height: 30, width: 30 }}
                        />
                        <Navbar.Brand 
                            as={SocialIcon} 
                            url="https://twitter.com/minoguepaul"
                            style={{ height: 30, width: 30 }}
                        />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )}

export default Navigation