import axios from 'axios'
const baseUrl = 'https://164.92.205.122:5000/language'

const getLanguage = (text) => {
    console.log("Getting language from ", text)
    const request = axios.get(process.env.REACT_APP_FLASK_ENDPOINT + 'language', {
        params: {
          inputText: text
        }
      })

    return request.then(response => response.data.data)
  }

export default { getLanguage }