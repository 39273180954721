import React from 'react';
import HorizontalLine from "../HorizontalLine"

const Heading = ( {size, text, blockId} ) => {
    console.log("Creating heading...")
    const headingId = 'heading-' + blockId
    return (
      <div>
          {
              size === 1 ? <h1 id={headingId}>{text}</h1>
              : size === 2 ? <div><h2 id={headingId}>{text}</h2><HorizontalLine></HorizontalLine></div>
              : size === 3 ? <h3 id={headingId}>{text}</h3>
              : size === 4 ? <h4 id={headingId}>{text}</h4>
              : <h5 id={headingId}>{text}</h5>
          }
      </div>
  )
  }
  
  export default Heading