import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Button, Form } from "react-bootstrap";
import detectLanguageService from "../../services/detect_language"


const DetectLanguageForm = () => {
    console.log("Creating detect language form...")
    const [inputText, setInputText] = useState("Hello world!")
    const [detectedLanguage, setDetectedLanguage] = useState("en")

    const handleInputChange = (event) => {
        setInputText(event.target.value)
    }

    const detectLanguage = event => {
        event.preventDefault()

        detectLanguageService
        .getLanguage(inputText)
        .then(language => {        
            setDetectedLanguage(language) 
          })
    }
    
    return (
        <div>
            <Formik>
                <Form onSubmit={detectLanguage}>
                    <Field id="inputText" name="inputText" maxLength="200" value={inputText} onChange={handleInputChange}/>
                    <p>This ISO code for this text's language is: {detectedLanguage}</p>
                    <Button
                        type="submit"
                        variant="success"
                        as="input"
                        size="md"
                        type="submit"
                        value="Detect language"
                        />
                </Form>
            </Formik>
        </div>
    )
};

export default DetectLanguageForm