import React from 'react';


const CreateSections = ({ sections }) => {
    console.log("Creating sections...")
    return (
            <ul>
            {sections.map((section) => (
            <li key={'heading-' + section.blockId}>
            <a style={{color: "black"}} href={`#${'heading-' + section.blockId}`}>{section.heading}</a>
                {section.subsections.length > 0 && (
                <ul>{section.subsections.map((subsection) => (
                    <li key={subsection.blockId}>
                        <a style={{color: "black"}} href={`#${'heading-' + subsection.blockId}`}>{subsection.heading}</a>
                        {subsection.subsections.length > 0 && (
                        <ul>{subsection.subsections.map((subsubsection) => (
                            <li key={subsubsection.blockId}>
                                <a style={{color: "black"}} href={`#${'heading-' + subsubsection.blockId}`}>{subsubsection.heading}</a>
                            </li>            
                            ))}          
                        </ul>        
                        )} 
                    </li>            
                    ))}          
                </ul>        
                )}      
            </li>
            ))}
            </ul>
    )
};

export default CreateSections