import React from 'react';

const HorizontalLine = () => {
    return (
        <hr
            style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 2
            }}
        />
    )
};

export default HorizontalLine