import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';
import Heading from "../Heading"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Accordion'

const About = () => {
    console.log("Rendering about page")
    return (
        <Container>
            <br/><br/>
            <Heading size="1" text="About me"/>
            <div>
                Hi there,<br/><br/>

                Welcome to my website!<br/><br/>
                
                I am an experienced Data Scientist/Machine Learning Engineer Manager with an MSc in Computing and a BSc in Industrial Mathematics from Technological University Dublin. 
                While I work primarily in the area of natural language processing, I am also interested in computer vision, sports analytics and the field of deep learning in general. <br/><br/>

                Outside of work, I enjoy playing football and guitar (but not at the same time).<br/><br/>

                Please see below for a more detailed breakdown of my experience to date.<br/><br/>                
            </div>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Card >
                        <Accordion.Header variant="link" eventKey="0">
                            Employment history
                        </Accordion.Header>       
                        <Accordion.Collapse eventKey="0">
                            <Accordion.Body>
                                <a href="https://www.edgetier.com/" style={{ color: '#000000'}}>
                                    <Heading size="3" text="EdgeTier"/>
                                </a>
                                <b>Data Scientist/Machine Learning Engineer -> Head of Data Science</b><br/>
                                <b>2019-Present</b><br/>

                                
                                My primary role at EdgeTier revolves around the development of WatchTower, our award winning monitoring and alerting system for contact centers. 
                                As Head of Data Science this involves liaising with out customer success and product teams to understand our current and prospective customers needs before coordinating 
                                the development, deployment, and scaling of AI microservices with the of the data science and engineering teams to service these requirements and improve our product offering.<br/><br/>

                                This work involves designing and deploying microservices which use NLP techniques to understand our customers contact centers, 
                                and trigger alerts when their customers start contacting about new issues, known topics of interest, or when there is a shift in the volume/response 
                                time on a particular queue before providing extra information regarding customer sentiment and agent performance related to these alerts. As part of this product, 
                                I also developed a semantic search system which allows our customers to perform multilingual semantic search across their 
                                data and use the results to train their own light-weight model to track custom topics over time<br/><br/>

                                The data analysed by WatchTower comes from a variety of systems such as Zendesk, Kustomer, Twilio, etc., so a portion of my time is often spent getting familiar with 
                                and developing scripts which pull data from these systems via their API's in real time. The data ingested from these systems is typically text data from a chat, email, survey, 
                                or social media interaction however in some cases we ingest and transcribe voice data through third party APIs.<br/><br/>

                                In addition to this, some of my time is spent developing client-specific topic models for our Arthur chat system product. This typically involves meeting with customers 
                                before using clustering techniques (typically a combination of sentence vectors, UMAP dimension reduction and HDBSCAN) to understand our customers most common contact 
                                reasons and from here using state of the art language models to detect these contact reasons in an attempt to deflect customers where possible.<br/><br/>

                                At EdgeTier I primarily work in Python, and more specifically in Tensorflow/Pytorch, scikit-learn, numpy, pandas, flask, sqlalchemy and matplotlib. Typically I will 
                                also hop in and out of postgres throughout the day for high-level exploratory analysis and debugging. When I am not working on data science related projects, 
                                I will also pick up some engineering tasks on our main API when required.<br/><br/>

                                From a non-technical perspective, in my role as Head of Data Science I am also responsible for managing the career progression and development of other member of the 
                                data science team, hiring and team planning, managing customer accounts and identifying potential upselling opportunities, and meeting potential investors to give them an 
                                overview of how the companies tech functions.<br/><br/>

                                <a href="https://www.ey.com/en_ie" style={{ color: '#000000'}}>
                                    <Heading size="3" text="EY"/>
                                </a>
                                <div>
                                    <b>Graduate -> Senior Data Analyst</b><br/>
                                    <b>2016-2019</b><br/>
                                    
                                    The main project I worked on in EY was based around the development of a suite of tools designed to assist auditors in their group scoping and interim review procedures. 
                                    These tools helped the audit teams identify which entities of large multi-national companies would be in scope for various audit procedures throughout the finanical year. 
                                    The suite of tools ended up receiving global certification and mandatory usage requirements within EY for group audits.<br/><br/> 

                                    Once these tools were being used across EY, I was responsible for coordinating data extraction and preparation tasks to facilitate larger clients 
                                    getting their data into the tools.<br/><br/> 

                                    In addition this, I oftentimes worked in a more external, client facing role with EY clients across the financial services, clinical research, 
                                    and construction industries to help streamline their reporting and compliance processes through the use machine learning, natural language processing, 
                                    and data visualisation techniques.<br/><br/> 

                                    In my role as a Senior Data Analyst I was also responsible for coaching, mentoring, and overseeing work performed by more junior members of 
                                    the team as well as running SQL and machine learning training for graduate joiners.<br/><br/>       
                                </div>
                </Accordion.Body>
                </Accordion.Collapse>
                </Card>
                </Accordion.Item>

                <Accordion.Item eventKey="0">
                    <Card >
                        <Accordion.Header variant="link" eventKey="0">
                            Education
                        </Accordion.Header>       
                        <Accordion.Collapse eventKey="0">
                            <Accordion.Body>
                                <a href="https://www.tudublin.ie/study/postgraduate/courses/computing-data-science2/" style={{ color: '#000000'}}>
                                    <Heading size="5" text="Master of Science in Computing: Data Science Stream (Part Time)"/>
                                </a>
                                <div>
                                    Technological University Dublin<br/>
                                    2017-2019<br/>
                                    First class honours<br/>
                                    Thesis: Multi-Sensory Deep Learning Architectures for Slam Dunk Scene Classification<br/>
                                </div>
                                <br/><br/>
                                <a href="https://www.tudublin.ie/study/undergraduate/courses/mathematical-sciences-tu874/" style={{ color: '#000000'}}>
                                    <Heading size="5" text="Bachelor of Science in Industrial Mathematics"/>
                                </a>
                                <div>
                                    Technological University Dublin<br/>
                                    2012-2016<br/>
                                    First class honours<br/>
                                    Thesis: Numerical Solutions to Systems of Differential Equations to Describe Coupled Cortical Columns<br/>
                                </div>
                </Accordion.Body>
                </Accordion.Collapse>
                </Card>
                </Accordion.Item>

                <Accordion.Item eventKey="0">
                    <Card >
                        <Accordion.Header variant="link" eventKey="0">
                            Technical Tools
                        </Accordion.Header>       
                        <Accordion.Collapse eventKey="0">
                            <Accordion.Body>
                                <div>
                                    <b>Data Science: </b>Python, R, SAS Enterprise Miner, MATLAB, SPSS<br/>
                                    <b>Data Processing: </b>Microsoft/Postgres/Oracle SQL, Pandas, deplyr<br/>
                                    <b>Data Visualisation:: </b>Tableau, Spotfire, Power BI, Metabase, React, matplotlib, ggplot<br/>
                                    <b>Cloud (AWS): </b>Sagemaker, OpenSearch, EC2, SQS/SNS, s3, IAM, Cognito, Cloudwatch, Automatic scaling<br/>
                                    <b>Engineering: </b>Flask, SQLAlchemy, Database Design<br/><br/><br/>
                                </div>
                </Accordion.Body>
                </Accordion.Collapse>
                </Card>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default About
