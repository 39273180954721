import React, { useState, useEffect } from 'react';
import CreateSections from "../CreateSections"
import sectionsService from "../../services/sections"
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Accordion'

const TableOfContents = ({ blogPostId }) => {
    const [sections, setSections] = useState([])
    console.log("Creating table of contents...")

    useEffect(
      () => {
        sectionsService
          .getSections(blogPostId)
          .then(initialSections=> {setSections(initialSections)})
      }, 
    [])
    const blogPostSections = (sections) ? sections : [];
    const createdSections = <CreateSections sections={blogPostSections} />
    const tableOfContents = (createdSections) ? createdSections : ''; 

    return  (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Card >
                    <Accordion.Header variant="link" eventKey="0">
                        Table of Contents
                    </Accordion.Header>       
                    <Accordion.Collapse eventKey="0">
                        <Accordion.Body>{tableOfContents}</Accordion.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion.Item>
        </Accordion>
    );
};

export default TableOfContents;