import React, { useState, useEffect } from 'react';
import blogPostsService from "../../services/blog_posts"
import { Container } from 'react-bootstrap';
import BlogPostPreview from "../BlogPostPreview"

const Home = () => {
    console.log("Rendering home page...")
    const [blogPosts, setBlogPosts] = useState([])

    useEffect(
        () => {
          blogPostsService
            .getAll()
            .then(initialBlogPosts => {setBlogPosts(initialBlogPosts)})
        }, 
      [])
    
    return (
        <Container>
          <br/><br/>
          {blogPosts.map(blogPost => (<BlogPostPreview key={blogPost.postId} blogPost={blogPost} />))}
        </Container>
      );
    };

export default Home