import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TableOfContents from '../TableOfContents'
import blogPostService from "../../services/blog_post"
import Heading from "../Heading"
import Block from "../Block"

const BlogPost = () => {

  let { blogPostId } = useParams();
  const [blogPost, setBlogPost] = useState([])

  console.log("Rendering blogpost", {blogPostId})

  useEffect(
    () => {
        blogPostService
        .getBlogPost(blogPostId)
        .then(initialBlogPost => {setBlogPost(initialBlogPost)})
    }, 
  [])
  const blocks = (blogPost && blogPost.blocks) ? blogPost.blocks : [];
  const publishedDate = (blogPost && blogPost.publishedDateTime) ? blogPost.publishedDateTime : '';
  const blogContent = blocks.map(block => <Block key={block.blockId} block={block}/>)
  return (
        <Container>
          <br/><br/>
          <Heading key = {blogPost.blogPostId} size = {1} text={blogPost.title}></Heading>
          <p>Published {publishedDate}</p>
          <TableOfContents blogPostId={ blogPostId }/>
          <br/>
          {blogContent}
        </Container>
      )
  }

export default BlogPost