import React from 'react';
import './App.css';
import Navigation from "./components/Navigation"
import Home from "./components/Home"
import About from "./components/About"
import BlogPost from "./components/BlogPost"
import OldBlogPost from "./components/OldBlogPost"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('G-11ZZME1JTQ');
ReactGA.pageview(window.location.pathname + window.location.search);


function App() {
  return (
    <BrowserRouter>
      <Navigation/>
      <br/>
      <Routes>
          <Route path='/posts/:blogPostId' element={<BlogPost />} />

          <Route path='/index.php/2021/12/12/working-with-datetimes-in-pandas/' element={<OldBlogPost blogPostId={"d3d57bdb-fbbb-4e86-b328-063f023a88b1"} />} />
          <Route path='/index.php/2021/07/23/deep-learning-activation-functions/' element={<OldBlogPost blogPostId={"ccf789e8-e5c9-49f5-a0ec-9cfebf155ea1"} />} />
          <Route path='/index.php/2021/07/03/building-a-simple-expected-pass-completion-xp-model-using-keras/' element={<OldBlogPost blogPostId={"8e4e1914-4cd9-4a0f-9cf0-9dbf3c47e1ce"} />} />
          <Route path='/index.php/2021/04/07/cryptography-implementing-a-vigenere-cipher-in-python/' element={<OldBlogPost blogPostId={"042eae36-a985-43b4-9624-a5b88e45041c"} />} />
          <Route path='/index.php/2021/02/27/creating-a-map-polygons-using-googles-my-maps-and-python/' element={<OldBlogPost blogPostId={"2a81457c-814a-4446-aa91-503886681571"} />} />
          <Route path='/index.php/2021/02/14/using-a-siamese-neural-network-to-create-a-simple-rhyme-detector/' element={<OldBlogPost blogPostId={"eca77f81-ff5f-4bf5-b8a6-16a50558c81a"} />} />
          <Route path='/index.php/2020/10/16/using-general-linear-models-to-simulate-gaelic-football-matches-in-python/' element={<OldBlogPost blogPostId={"0a803c0b-3d69-4510-afcc-53f88955ab51"} />} />
          <Route path='/index.php/2020/08/24/using-network-analysis-and-eigenvector-centrality-to-identify-arsenals-most-influential-invincibles/' element={<OldBlogPost blogPostId={"8cdb1f03-f215-4060-908f-d21d403bf9e5"} />} />
          <Route path='/index.php/2020/08/03/an-introduction-to-non-linear-programming/' element={<OldBlogPost blogPostId={"a0d8c837-a40d-4b17-9d30-e0bd36a6befc"} />} />
          <Route path='/index.php/2020/07/20/an-introduction-to-linear-programming-in-python/' element={<OldBlogPost blogPostId={"71924725-4c6f-46eb-b732-b5806ddc0152"} />} />
          <Route path='/index.php/2020/06/22/using-opencv-scipy-and-scikit-learn-to-develop-a-simple-gaelic-football-tracking-system' element={<OldBlogPost blogPostId={"d38c1e54-53fb-4fe0-bdce-52c3ebe5c703"} />} />
          <Route path='/index.php/2020/04/26/using-the-rasa-framework-to-implement-a-simple-medical-diagnosis-bot/' element={<OldBlogPost blogPostId={"6285da6b-5cdf-462d-9d8e-218c7450402b"} />} />
          <Route path='/index.php/2020/03/28/using-the-hugging-face-bart-model-to-summarise-match-reports-from-the-2019-all-ireland-championship-finals/' element={<OldBlogPost blogPostId={"374d3e31-aee8-485b-b29c-b00a0bc74f5e"} />} />
          <Route path='/index.php/2020/03/22/telegram-api-building-a-weather-bot-in-30-lines-of-code/' element={<OldBlogPost blogPostId={"23575c18-f3fe-4065-9616-a1b97630b988"} />} />
          <Route path='/index.php/2020/01/12/data-integration-transformation-and-visualisation-using-pandas/' element={<OldBlogPost blogPostId={"a4d80b5-26da-4dff-8e3d-b888102e68a21"} />} />
          <Route path='/index.php/2019/09/29/introduction-to-cosine-similarity/' element={<OldBlogPost blogPostId={"0de56ac8-914a-4056-9bf9-005572959bb1"} />} />

          <Route path='/about' element={<About />} />
          <Route path='/' exact element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
