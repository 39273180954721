import React from 'react';
import SimpleBarChart from "../SimpleBarChart"
import SimpleScatter from "../SimpleScatter"

const DataVisualisation = ( {block} ) => {
    console.log("Creating data visualisatio...n")
    return (
        <div>
            {
                block.dataVisualisationType === "SimpleBarChart" ? <SimpleBarChart data={block.data}></SimpleBarChart>
                : block.dataVisualisationType === "SimpleScatter"  ? <SimpleScatter data={block.data}></SimpleScatter>
                : <SimpleBarChart data={block.data}></SimpleBarChart>
            }            
            <br/>
        </div>
    )
    }
  
  export default DataVisualisation