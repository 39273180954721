import React from 'react';
import DetectLanguageForm from "../DetectLanguageForm"

const Application = ( {block} ) => {
    console.log("Creating Application...")
    return (
        <div>
            {
                block.application === "DetectLanguageForm" ? <DetectLanguageForm />
                : <DetectLanguageForm />
            }            
            <br/>
        </div>
    )
    }
  
  export default Application