import React from 'react';
import Heading from "../Heading"
import Paragraph from "../Paragraph"
import Code from "../Code"
import Image from "../Image"
import Table from "../Table"
import DataVisualisation from "../DataVisualisation"
import Application from "../Application"

const Block = ( {block} ) => {
    
    console.log("Rendering block...")
    return (
        <div>
            {
                block.blockType === "Heading" ? <Heading 
                    size={block.details.headingSize} text={block.details.heading} blockId={block.blockId}
                    ></Heading>
                : block.blockType === "Paragraph"  ? <Paragraph text={block.details.paragraph}></Paragraph>
                : block.blockType === "Code"  ? <Code language={block.details.codeLanguage} codeString={block.details.code}></Code>
                : block.blockType === "Image"  ?  <Image fileName={block.details.fileName} caption={block.details.caption}></Image>
                : block.blockType === "Table"  ?  <Table columns={block.details.data.columns} data={block.details.data.data}></Table>
                : block.blockType === "Data Visualisation"  ?  <DataVisualisation block={block.details}></DataVisualisation>
                : block.blockType === "Application"  ?  <Application block={block.details}></Application>
                : <Paragraph key={block.blockId} text={block.blockId}></Paragraph>
            }            
            <br/>
        </div>
    )
    }
  
  export default Block