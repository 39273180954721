import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, Label, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CustomTooltip from "../CustomTooltip"

const SimpleScatter = ({ data }) => {
    console.log("Creating simple scatter")
    const dataPoints = (data && data.dataPoints) ? data.dataPoints : [];
    return (
        <ResponsiveContainer width="100%" height={400}>
        <ScatterChart
            width={1000}
            height={500}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" dataKey={data.xVar}>
                <Label offset={-5} position='insideBottom' style={{ textAnchor: 'middle' }}>{data.xVar}</Label>
            </XAxis>
            <YAxis type="number" dataKey={data.yVar}>
                <Label angle={270} position='left' style={{ textAnchor: 'middle' }}>{data.yVar}</Label>
            </YAxis> 
            <Tooltip content={<CustomTooltip />} />
            <Scatter name="A school" data={dataPoints} fill="#2c7fb8" />
        </ScatterChart>
        </ResponsiveContainer>
    );
}
export default SimpleScatter