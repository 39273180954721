import React from 'react';
import ReactMarkdown from 'react-markdown'

const Paragraph = ( {text} ) => {
    console.log("Creating paragraph...")
    return (
        <ReactMarkdown>
            { text }
        </ReactMarkdown>
    )
    }
  
  export default Paragraph