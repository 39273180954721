import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Code = ({language, codeString}) => {
  console.log("Creating code block...")
  return (
    <SyntaxHighlighter language={language} style={darcula}>
      {codeString}
    </SyntaxHighlighter>
  );
};

export default Code