import React from 'react';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';

const CustomTooltip = props => {
    // payload[0] doesn't exist when tooltip isn't visible
    if (props.payload[0] != null) {
      const newPayload = [
        {value: props.payload[0].payload.otherDimension,},
        ...props.payload,
      ];
  
      // we render the default, but with our overridden payload
      return <DefaultTooltipContent {...props} payload={newPayload} />;
    }
  
    // we just render the default
    return <DefaultTooltipContent {...props} />;
  };

  export default CustomTooltip