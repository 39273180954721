import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import Figure from 'react-bootstrap/Figure'
import { Row, Col } from "react-bootstrap";

const Image = ({fileName, caption}) => {
    console.log("Creating image...")

    return (
        <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Figure className="text-center" align="center">
              <Figure.Image src={"/images/" + fileName} alt='missing' resizemode={'cover'}/>
              <Figure.Caption>{caption}</Figure.Caption>
          </Figure>
        </Col>
      </Row>
  );
};

export default Image