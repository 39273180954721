import React from 'react';
import { BarChart, Bar, Label, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CustomTooltip from "../CustomTooltip"

const SimpleBarChart = ({ data }) => {
    console.log("Creating simple bar chart...")
    const dataPoints = (data && data.dataPoints) ? data.dataPoints : [];
    return (
        <ResponsiveContainer width="100%" height={400}>
        <BarChart
            width={1000}
            height={500}
            data={dataPoints}
            margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={data.xVar} />
            <YAxis type="number" dataKey={data.yVar}>
                <Label angle={270} position='left' style={{ textAnchor: 'middle' }}>{data.yVar}</Label>
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey={data.yVar} fill="#2c7fb8" />
        </BarChart>
        </ResponsiveContainer>
    );
}
export default SimpleBarChart